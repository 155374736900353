import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { ISGWTConnectOpenIDStandardClaims } from '@sgwt/connect-core/dist/src/SGWTConnectOpenIDUserInfo';
import { BUS_USER_INFO } from '../../../common/auth/bus-topics';
import {
  addAuthenticationInfoInRequestHeader,
  checkResponseStatus,
  isUserAuthorized,
  parseResponseAsJson,
  whichEnvironment,
} from '../../../common/sgwt-widgets-utils';
import {
  ISgwtHelpCenterCategory,
  ISgwtHelpCenterEnvironmentInfo,
  ISgwtHelpCenterKnowledge,
  ISgwtHelpCenterMessageTopic,
  ISgwtHelpCenterTopic,
  ISgwtHelpCenterUser,
} from '../sgwt-help-center.types';
import { ENVIRONMENT_ENDPOINTS } from './help-center.endpoints';

const BUS_ACCESS_TOKEN = 'sg-connect.access-token';

export const getHelpCenterConfiguration = async (widgetConfiguration: WidgetConfiguration, applicationId: string) => {
  const environment = whichEnvironment(widgetConfiguration);
  const authorized = isUserAuthorized(widgetConfiguration);
  const api =
    ENVIRONMENT_ENDPOINTS[authorized ? 'help-center-api' : 'help-center-anonymous-api'][environment].apiEndpoint;
  const options: any = addAuthenticationInfoInRequestHeader(
    { method: 'GET', headers: {} },
    widgetConfiguration,
    'sg-connect-v2',
  );

  return fetch(`${api}/configuration/${applicationId}`, options).then(checkResponseStatus).then(parseResponseAsJson);
};

export const authenticateWithSgConnectV2 = async (
  widgetConfiguration: WidgetConfiguration,
  environmentInfo: ISgwtHelpCenterEnvironmentInfo | null,
): Promise<ISgwtHelpCenterUser | null | undefined> => {
  // First check if the Widget bus has the userInfo, to avoid call to SG Connect `/userinfo` endpoint.
  const userInfo: ISGWTConnectOpenIDStandardClaims | undefined =
    widgetConfiguration.bus.dangerouslyGetCurrentValue<ISGWTConnectOpenIDStandardClaims>(BUS_USER_INFO);
  if (userInfo && userInfo.sub) {
    return Promise.resolve({
      username: userInfo.name,
      email: userInfo.sub,
      internal: !!userInfo.origin_network && userInfo.origin_network === 'LAN',
    });
  }
  // Not found in Widgets bus, so we call `/userinfo` endpoint.
  if (environmentInfo && environmentInfo.authenticationEndpoint) {
    const authorization: string | undefined = window.sgwtConnect
      ? window.sgwtConnect.getAuthorizationHeader()
      : widgetConfiguration.bus.dangerouslyGetCurrentValue<string>(BUS_ACCESS_TOKEN);
    if (authorization) {
      return fetch(`${environmentInfo.authenticationEndpoint as string}/oauth2/userinfo`, {
        headers: {
          accept: 'application/json',
          authorization,
        },
        method: 'GET',
        mode: 'cors',
      })
        .then(checkResponseStatus)
        .then(parseResponseAsJson)
        .then((json: any): ISgwtHelpCenterUser | null | undefined => {
          if (json.sub) {
            return {
              username: json.name,
              email: json.sub,
            };
          } else {
            return undefined;
          }
        })
        .catch((): undefined => undefined);
    }
  }
  return Promise.resolve(undefined);
};

export function getCategoryById(
  categories: ISgwtHelpCenterCategory[] | null,
  id: string,
): ISgwtHelpCenterCategory | null {
  const ret: ISgwtHelpCenterCategory | null = null;
  if (!categories || !id) {
    return ret;
  }
  const results: ISgwtHelpCenterCategory[] = categories.filter(
    (category: ISgwtHelpCenterCategory): boolean => category.id === id,
  );
  return results && results.length === 1 ? results[0] : ret;
}

export function getCategoryTitleById(categories: ISgwtHelpCenterCategory[] | null, id: string): string {
  let ret = '';
  if (!categories || !id) {
    return ret;
  }
  const results: ISgwtHelpCenterCategory[] = categories.filter(
    (category: ISgwtHelpCenterCategory): boolean => category.id === id,
  );
  if (results && results.length === 1) {
    return results[0].title;
  } else {
    // @TODO
    // categories.forEach((category: ISgwtHelpCenterCategory): void => {});
    // no break keyword, find a more efficient way...
    for (const category of categories) {
      if (!ret && category.subCategories && category.subCategories instanceof Array) {
        ret = getCategoryTitleById(category.subCategories, id);
        if (ret) {
          return ret;
        }
      }
    }
    return ret;
  }
}

export function getTopicsByCategoryId(
  topics: ISgwtHelpCenterTopic[] | null,
  id: string,
): ISgwtHelpCenterTopic[] | null {
  const ret: ISgwtHelpCenterTopic[] | null = null;
  if (!topics || !id) {
    return ret;
  }
  return topics.filter((topic: ISgwtHelpCenterTopic): boolean => topic.categoryId === id);
}

export function getTopicPropertyById(topics: ISgwtHelpCenterTopic[] | null, id: string, property: string): string {
  const ret = '';
  if (!topics || !id || !property) {
    return ret;
  }
  const results: ISgwtHelpCenterTopic[] = topics.filter((topic: ISgwtHelpCenterTopic): boolean => topic.id === id);
  return results && results.length === 1 ? (results[0] as any)[property] : ret;
}

const re =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function getValidEmail(value?: string | null): string {
  if (!value || !value.trim()) {
    return '';
  }
  let emails = value.trim();
  if (!/@/.test(emails)) {
    try {
      emails = atob(emails);
    } catch (e) {
      return '';
    }
  }
  return getValidEmails(emails);
}
function getValidEmails(emails: string): string {
  const result: string[] = [];
  const values = emails.split(/[,;]/);
  for (const value of values) {
    if (re.test(value)) {
      result.push(value);
    }
  }
  return result.join(',');
}

export function isInternalEmail(email: string): boolean {
  if (!email || email.indexOf('@') === -1) {
    return false;
  }
  const VALID_MAIL_DOMAINS = [
    'sgcib.com',
    'sgss.socgen.com',
    'socgen.com',
    'aptp.accenture.com',
    'cdn.fr',
    'descartes-trading.com',
    'hermes.si.socgen',
    'kb.cz',
    'kleinwortbenson.com',
    'lyxor.com',
    'newedge.com',
    'orbeo.com',
    'rosbank.ru',
    's2e-net.com',
    'sghambros.com',
    'sgpriv.be',
    'sgprivasia.com',
    'sgprivjapan.com',
    'sgss.com',
    'sgss.socgen.co.za',
    'sgss.socgen.de',
    'sgss.socgen.it',
    'sgsssocgen.com',
    'smbctb.co.jp',
    'socgen.com.cn',
    'socgen.it',
  ];
  const domain = email.split('@')[1];
  return VALID_MAIL_DOMAINS.indexOf(domain.toLowerCase()) !== -1;
}

export function isMessageTopicIsAComplaint(topic: string, i18nLabel: string): boolean {
  return topic === i18nLabel || topic === 'Make a complaint' || topic === 'Déposer une réclamation';
}

export function getSendToByTopic(
  messageTopics: ISgwtHelpCenterMessageTopic[] | null,
  topic: string,
  i18n: any,
  environment: string,
): string {
  const COMPLAINT_EMAIL = 'mark-clientclaims.world@sgcib.com';
  const ret = '';
  if (environment === 'production') {
    if (isMessageTopicIsAComplaint(i18n, topic)) {
      return getValidEmail(COMPLAINT_EMAIL);
    }
  }
  if (!messageTopics || !topic) {
    return ret;
  }
  const results: ISgwtHelpCenterMessageTopic[] = messageTopics.filter(
    (messageTopic: ISgwtHelpCenterMessageTopic): boolean => messageTopic.topic === topic,
  );
  return results && results.length === 1
    ? results[0].sendTo !== undefined && results[0].sendTo
      ? getValidEmail(results[0].sendTo)
      : ret
    : ret;
}

export const findIP: Promise<string> = new Promise((cb: any): void => {
  // https://stackoverflow.com/a/32841164
  const win: any = window;
  const RTCPeerConnection = win.RTCPeerConnection || win.mozRTCPeerConnection || win.webkitRTCPeerConnection;
  if (!RTCPeerConnection) {
    return;
  }
  const pc = new RTCPeerConnection({
    iceServers: [],
  });
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = (): void => {};
  pc.createDataChannel('');
  pc.createOffer((c: any): void => pc.setLocalDescription(c, noop, noop), noop);
  pc.onicecandidate = (ice: any): void => {
    try {
      ice.candidate.candidate.match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g).forEach(cb);
    } catch (e) {
      // Ignore error.
    }
  };
});

const fetchAsyncKnowledge = (
  widgetConfiguration: WidgetConfiguration,
  url: string,
): Promise<ISgwtHelpCenterKnowledge> =>
  fetch(
    url,
    addAuthenticationInfoInRequestHeader(
      {
        headers: {
          accept: 'application/json',
        },
      },
      widgetConfiguration,
      'sg-connect-v2',
    ),
  )
    .then(checkResponseStatus)
    .then(parseResponseAsJson)
    .catch((err: Error): void => {
      throw err;
    }) as Promise<ISgwtHelpCenterKnowledge>;

const aggregatesAsyncKnowledge = (
  ret: ISgwtHelpCenterKnowledge,
  response: ISgwtHelpCenterKnowledge,
): ISgwtHelpCenterKnowledge => {
  if (ret.categories && response.categories) {
    ret.categories = ret.categories.concat(response.categories);
  }
  if (ret.topics && response.topics) {
    ret.topics = ret.topics.concat(response.topics);
  }
  return ret;
};

export const getAsyncKnowledge = (
  widgetConfiguration: WidgetConfiguration,
  urls: string[] | null,
): Promise<ISgwtHelpCenterKnowledge | undefined> => {
  const promises: Promise<ISgwtHelpCenterKnowledge>[] = [];
  if (urls && typeof urls === 'object' && urls instanceof Array && urls.length !== 0) {
    urls.forEach((url: string): void => {
      promises.push(fetchAsyncKnowledge(widgetConfiguration, url));
    });
    /* } else if (typeof urls === 'string') {
    promises.push(fetchAsyncKnowledge(urls as string)); */
  } else {
    return Promise.resolve(undefined);
  }

  let lastError: Error | null = null;
  const catchAsyncKnowledgeUrl = (promise: Promise<ISgwtHelpCenterKnowledge>): Promise<undefined> =>
    promise.catch((e) => {
      lastError = e;
      return undefined;
    }) as Promise<undefined>;

  return Promise.all(promises.map(catchAsyncKnowledgeUrl)).then(
    (results: Array<Promise<ISgwtHelpCenterKnowledge> | undefined>): ISgwtHelpCenterKnowledge | undefined => {
      const responses: Array<Promise<ISgwtHelpCenterKnowledge> | undefined> = results.filter(
        (result: Promise<ISgwtHelpCenterKnowledge> | undefined): boolean => result !== undefined,
      );

      if (responses.length === 0) {
        if (lastError !== null) {
          throw lastError;
        }
        return undefined;
      }

      let ret: ISgwtHelpCenterKnowledge = { categories: [], topics: [] };
      responses.forEach((response?: Promise<ISgwtHelpCenterKnowledge>): void => {
        if (response) {
          ret = aggregatesAsyncKnowledge(ret, response as ISgwtHelpCenterKnowledge);
        }
      });
      return ret;
    },
  );
};
